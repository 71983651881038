<template>
  <div
    v-if="file.need_feedback"
    class="card"
  >
    <div class="card-body">
      <h4 class="">
        Need feedback
      </h4>
      <hr>
      <div
        v-if="file.response"
        class="mb-1"
      >
        <table class="table table-condensed table-striped table-bordered">
          <tr class="border">
            <td class="border">
              {{ file.response.name }}<br><small>Uploaded: {{ file.response.created_at }}</small>
            </td>
            <td class="text-end border">
              <div class="row">
                <div class="col-6">
                  <a
                    v-if="file.need_feedback_check"
                    style="color: red;"
                    title="Delete file"
                    @click="deleteResponse(file.response.id)"
                  ><i data-feather="trash-2" /></a>
                </div>
                <div class="col-6">
                  <a
                    :href="file.response_url"
                    target="_blank"
                    title="Delete file"
                  ><i data-feather="eye" /></a>
                </div>
              </div>
            </td>
          </tr>
        </table>

      </div>
      <hr v-if="file.response">
      <div
        v-if="file.need_feedback_check"
        class="mb-1"
      >
        <label
          for=""
          class="form-label"
        ><strong>Upload file</strong></label>
        <div
          class="input-group custom-file-button"
        >
          <label
            class="input-group-text"
            for="formFile"
          >Select file</label>
          <input
            id="formFile"
            :ref="`file-present-report`"
            class="form-control"
            type="file"
          >
        </div>
      </div>
      <a
        v-if="file.need_feedback_check"
        class="btn btn-small btn-primary"
        @click="upload"
      >Upload</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      file: 'cae/file',
      filters: 'filters/filters',
    }),
  },
  mounted() {

  },
  methods: {
    async deleteResponse(id) {
      Vue.swal({
        title: 'Do you want to delete the feedback response?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          await this.$store.dispatch('cae/deleteResponse', { filters: this.filters, id, file: this.file.id })
        }
      })
    },
    async upload() {
      const file = this.$refs['file-present-report'].files[0]

      if (!file) {
        this.$toastr.error('', 'Please, upload a file first')
      } else {
        await this.$store.dispatch('cae/uploadResponse', { filters: this.filters, response: file, file: this.file.id })
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
}
</script>
